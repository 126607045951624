import axios from 'axios';

const apiClient = axios.create({
  baseURL: process.env.VUE_APP_API_BASE_URL, // 从环境变量中获取基础路径
  withCredentials: true, // 支持带上认证信息，如 Cookies
  // 你还可以在这里设置其他默认配置，如 headers, timeout 等
});
console.log("API Base URL:", process.env.VUE_APP_API_BASE_URL);

export default apiClient;
