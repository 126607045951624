<template>
  <el-menu
    :default-active="activeIndex"
    class="sidebar"
    background-color="#545c64"
    text-color="#fff"
    active-text-color="#ffd04b">
    <el-menu-item index="1">
      <router-link to="/data-sync">数据同步</router-link>
    </el-menu-item>
    <el-menu-item index="2">
      <router-link to="/other">其他</router-link>
    </el-menu-item>
  </el-menu>
</template>

<script>
export default {
  name: 'SideBar',
  data() {
    return {
      activeIndex: '1'
    };
  }
}
</script>

<style scoped>
.sidebar {
  display: flex;
  flex-direction: column;
  width: 200px;
  height: 100vh;
  background: linear-gradient(to bottom, #4b6cb7, #182848);
  border-right: none;
  transition: all 0.3s ease;
}

.el-menu-item {
  padding: 15px 20px;
  font-size: 16px;
  font-weight: bold;
  transition: background-color 0.3s ease;
  white-space: nowrap;
}

.el-menu-item:not(:last-child) {
  border-bottom: 1px solid #ffffff30;
}

.el-menu-item:hover {
  background-color: #2c3e50;
}

/* Style for active menu item */
.el-menu-item.is-active, .el-menu-item .router-link-exact-active {
  background-color: #2c3e50;
  color: #ffd04b;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .sidebar {
    width: 100%;
    height: auto;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1000;
  }
  .el-menu-item {
    font-size: 14px;
    padding: 10px 15px;
  }
}

@media (max-width: 480px) {
  .sidebar {
    width: 100%;
    height: auto;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1000;
    overflow-y: auto;
  }
  .el-menu-item {
    font-size: 12px;
    padding: 8px 10px;
  }
}
</style>
