<template>
  <div class="login-container">
    <div class="login-box">
      <div class="logo">
        <img src="@/assets/logo.png" alt="Logo" />
      </div>
      <h1>Log in to Your App</h1>
      <form @submit.prevent="login">
        <div class="input-group">
          <input type="text" v-model="username" placeholder="Username" required />
        </div>
        <div class="input-group">
          <input type="password" v-model="password" placeholder="Password" required />
        </div>
        <button type="submit">Log In</button>
        <div class="checkbox">
          <input type="checkbox" id="remember" />
          <label for="remember">Remember Me</label>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import apiClient from '@/apiClient';

export default {
  data() {
    return {
      username: '',
      password: ''
    };
  },
  methods: {
    async login() {
      try {
        const response = await apiClient.post('/auth/login', {
          username: this.username,
          password: this.password
        });
        localStorage.setItem('token', response.data.token);
        this.$router.push('/home');
      } catch (error) {
        alert('Login failed: ' + error.response.data);
      }
    }
  }
}
</script>

<style scoped>
.login-container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background-color: #f4f4f4;
}

.login-box {
  width: 400px;
  padding: 40px;
  background-color: white;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  text-align: center;
}

.logo img {
  width: 80px;
  height: auto;
  margin-bottom: 20px;
}

h1 {
  margin-bottom: 20px;
  font-size: 24px;
  color: #333;
}

.input-group {
  width: 70%;
  margin: 0 auto 20px auto; 
  display: flex;
  justify-content: center;
}

input[type="text"], input[type="password"] {
  width: 100%;
  padding: 10px;
  border: 2px solid #ccc;
  border-radius: 4px;
  text-align: center;
  border-radius: 20px; /* 增加圆角值 */
}

button {
  width: 50%;
  padding: 10px;
  background-color: #0056b3;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

button:hover {
  background-color: #004494;
}

.checkbox {
  margin-top: 20px;
  text-align: left;
}

.checkbox input {
  margin-right: 10px;
}

.checkbox label {
  color: #666;
}
</style>
