import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import './globalErrorHandler'

// 全局错误处理程序
const resizeObserverLoopErr = /ResizeObserver loop limit exceeded/;
window.addEventListener('error', (e) => {
  if (resizeObserverLoopErr.test(e.message)) {
    e.stopImmediatePropagation();
  }
});

const app = createApp(App)
app.use(router)
app.use(ElementPlus)
app.mount('#app')
