<template>
  <el-container class="homepage-container">
    <el-aside class="sidebar-container" v-show="!isMobile || sidebarVisible">
      <sideBar />
    </el-aside>
    <el-container>
      <el-header class="header-container">
        <el-button
          class="menu-toggle-btn"
          icon="el-icon-menu"
          @click="toggleSidebar"
          v-if="isMobile"
        />
        <h1 class="title">数据同步系统</h1>
      </el-header>
      <el-main>
        <router-view />
      </el-main>
    </el-container>
  </el-container>
</template>

<script>
import SideBar from "./SideBar.vue";

export default {
  name: "HomePage",
  components: {
    SideBar,
  },
  data() {
    return {
      isMobile: false,
      sidebarVisible: true,
    };
  },
  created() {
    this.checkScreenSize();
    window.addEventListener("resize", this.checkScreenSize);
  },
  beforeUnmount() {
    window.removeEventListener("resize", this.checkScreenSize);
  },
  methods: {
    checkScreenSize() {
      this.isMobile = window.innerWidth <= 768;
      if (this.isMobile) {
        this.sidebarVisible = false;
      }
    },
    toggleSidebar() {
      this.sidebarVisible = !this.sidebarVisible;
    },
  },
};
</script>

<style scoped>
.homepage-container {
  height: 100vh;
}

.sidebar-container {
  width: 200px;
  background: linear-gradient(to bottom, #4b6cb7, #182848);
  transition: all 0.3s ease;
}

.header-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 20px;
  background-color: #545c64;
  color: white;
}

.title {
  margin: 0;
}

.menu-toggle-btn {
  margin-right: 10px;
}
</style>
