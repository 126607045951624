<template>
  <div>
    <el-button type="primary" @click="showSyncDialogAction" class="sync-button"
      >数据同步</el-button
    >
    <!-- 搜索框 -->
    <el-input
      v-model="searchQuery"
      placeholder="请输入同步名称或SKU"
      class="search-box"
      @keyup.enter="searchDataSync"
      style="width: 300px; float: right; margin-bottom: 20px"
    >
      <template #append>
        <el-button @click="searchDataSync">搜索</el-button>
      </template>
    </el-input>
    <el-table :data="syncItems" style="width: 100%" v-loading="pageloading">
      <el-table-column prop="name" label="同步名称"></el-table-column>
      <el-table-column
        prop="skuShopify"
        label="SKU (Shopify)"
      ></el-table-column>
      <el-table-column prop="skuEbay" label="SKU (Ebay)"></el-table-column>
      <el-table-column
        prop="skuWordpress"
        label="SKU (Wordpress)"
      ></el-table-column>
      <el-table-column label="操作" align="center">
        <template #default="{ row }">
          <div class="action-buttons">
            <el-button size="mini" @click="editItem(row)">编辑</el-button>
            <el-button
              size="mini"
              type="primary"
              @click="openSyncStockDialog(row)"
              >同步库存</el-button
            >
            <el-button size="mini" type="danger" @click="deleteItem(row)"
              >删除</el-button
            >
          </div>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="currentPage"
      :page-sizes="[20, 40, 60, 80]"
      :page-size="20"
      layout="total, sizes, prev, pager, next, jumper"
      :total="totalItems"
    >
    </el-pagination>

    <!-- 同步库存对话框 -->
    <el-dialog title="同步库存" v-model="showSyncStockDialog" width="400px">
      <el-form :model="syncStockData">
        <el-form-item label="库存数量" label-width="120px">
          <el-input
            v-model="syncStockData.stock"
            type="number"
            placeholder="请输入库存数量"
          ></el-input>
        </el-form-item>
      </el-form>
      <template #footer>
        <div class="dialog-footer">
          <el-button @click="showSyncStockDialog = false">取消</el-button>
          <el-button
            type="primary"
            @click="syncStock"
            :loading="syncLoading"
            :disabled="syncLoading"
            >确认同步</el-button
          >
        </div>
      </template>
    </el-dialog>

    <el-dialog
      title="新增/编辑数据同步任务"
      v-model="showSyncDialog"
      width="500px"
    >
      <el-form :model="formData">
        <el-form-item label="同步名称" label-width="120">
          <el-input v-model="formData.name" style="width: 200px"></el-input>
        </el-form-item>
        <el-form-item label="SKU (Shopify)" label-width="120">
          <el-select
            v-model="formData.skuShopify"
            filterable
            remote
            reserve-keyword
            placeholder="Please enter a sku"
            :remote-method="fetchSkuDataShopify"
            :loading="loading"
            style="width: 200px"
          >
            <el-option
              v-for="item in shopifyOptions"
              :key="item.label"
              :label="item.label"
              :value="item.label"
            />
          </el-select>
        </el-form-item>
        <el-form-item label="SKU (Ebay)" label-width="120">
          <el-select
            v-model="formData.skuEbay"
            filterable
            remote
            reserve-keyword
            placeholder="Please enter a sku"
            :remote-method="fetchSkuDataEbay"
            :loading="loading"
            style="width: 200px"
          >
            <el-option
              v-for="item in ebayOptions"
              :key="item.label"
              :label="item.label"
              :value="item.label"
            />
          </el-select>
        </el-form-item>
        <el-form-item label="SKU (WordPress)" label-width="120">
          <el-select
            v-model="formData.skuWordpress"
            filterable
            remote
            reserve-keyword
            placeholder="Please enter a sku"
            :remote-method="fetchSkuDataWordpress"
            :loading="loading"
            style="width: 200px"
          >
            <el-option
              v-for="item in wordpressOptions"
              :key="item.label"
              :label="item.label"
              :value="item.label"
            />
          </el-select>
        </el-form-item>
      </el-form>
      <template v-slot:footer>
        <div class="dialog-footer">
          <el-button @click="showSyncDialog = false">取消</el-button>
          <el-button type="primary" @click="syncData">保存同步任务</el-button>
        </div>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import apiClient from "@/apiClient";

export default {
  name: "DataSync",
  data() {
    return {
      syncLoading: false,
      searchQuery: "",
      syncItems: [],
      loading: false,
      pageloading: false,
      currentPage: 1,
      totalItems: 0,
      showSyncDialog: false,
      showSyncStockDialog: false, // 控制同步库存对话框的显示
      shopifyOptions: [],
      ebayOptions: [],
      wordpressOptions: [],
      formData: {
        name: "",
        skuShopify: "",
        skuEbay: "",
        skuWordpress: "",
      },
      syncStockData: {
        id: null, // 当前行的ID
        stock: 0, // 输入的库存数量
        name: "", // 同步的名称
        skuShopify: "",
        skuEbay: "",
        skuWordpress: "",
      },
    };
  },
  methods: {
    showSyncDialogAction() {
      this.formData = {};
      this.showSyncDialog = true;
    },
    searchDataSync() {
      // 重新加载数据，并进行搜索
      this.currentPage = 1;
      this.fetchSyncData();
    },
    syncData() {
      // 检查 formData 是否包含 id 属性来决定是创建还是更新
      if (this.formData.id) {
        // 更新现有记录
        this.updateItem(this.formData);
      } else {
        // 创建新记录
        this.createItem(this.formData);
      }
      this.showSyncDialog = false;
    },

    createItem(data) {
      console.log("存储方法");
      data.idEbay = this.ebayOptions[0].value;
      data.idShopify = this.shopifyOptions[0].value;
      data.idWordpress = this.wordpressOptions[0].value;
      apiClient
        .post("/dataSync/save", data)
        .then(() => {
          this.$message({
            type: "success",
            message: "数据同步任务创建成功!",
          });
          this.fetchSyncData(this.currentPage);
          this.showSyncDialog = false;
        })
        .catch((error) => {
          console.error("创建数据同步任务失败", error);
          if (error.response && error.response.data) {
            this.$message.error(`创建失败: ${error.response.data}`);
          } else {
            this.$message.error("创建数据同步任务失败，请稍后再试");
          }
          this.showSyncDialog = true;
        });
    },

    updateItem(data) {
      apiClient
        .put(`/dataSync/update`, data)
        .then(() => {
          this.$message({
            type: "success",
            message: "数据同步任务更新成功!",
          });
          this.fetchSyncData(this.currentPage);
          this.showSyncDialog = false;
        })
        .catch((error) => {
          console.error("更新数据同步任务失败", error);
          if (error.response && error.response.data) {
            this.$message.error(`更新失败: ${error.response.data}`);
          } else {
            this.$message.error("更新数据同步任务失败，请稍后再试");
          }
          this.showSyncDialog = true;
        });
    },

    fetchSyncData(page = 1) {
      const params = {
        page: page,
        size: 20,
        search: this.searchQuery,
      };
      this.pageloading = true;
      apiClient
        .get("/dataSync/list", { params })
        .then((response) => {
          this.syncItems = response.data.items;
          this.totalItems = response.data.total;
          this.pageloading = false;
        })
        .catch((error) => {
          console.error("数据加载失败", error);
          this.pageloading = false;
        });
    },
    editItem(item) {
      this.formData = { ...item };
      this.showSyncDialog = true;
    },
    deleteItem(item) {
      this.$confirm(`确定要删除同步任务 "${item.name}" 吗?`, "警告", {
        confirmButtonText: "确认",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          apiClient
            .delete(`/dataSync/delete/${item.id}`)
            .then(() => {
              this.$message({
                type: "success",
                message: "删除成功!",
              });
              this.fetchSyncData(this.currentPage);
            })
            .catch((error) => {
              console.error("删除失败", error);
              this.$message({
                type: "error",
                message: `删除失败: ${error.response.data || "未知错误"}`,
              });
            });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    handleSizeChange(size) {
      this.fetchSyncData(this.currentPage, size);
    },
    handleCurrentChange(page) {
      this.fetchSyncData(page);
    },

    openSyncStockDialog(row) {
      this.syncStockData = { ...row, stock: 0 };
      this.showSyncStockDialog = true;
    },

    async syncStock() {
      if (this.syncLoading) return; // 防止二次点击
      this.syncLoading = true;
      try {
        await apiClient.post("/dataSync/syncStock", this.syncStockData);
        this.$message.success("库存同步成功！");
        this.showSyncStockDialog = false;
      } catch (error) {
        this.$message.error(
          "库存同步失败：" + (error.response?.data || "未知错误")
        );
      } finally {
        this.syncLoading = false; // 操作完成后重置加载状态
      }
    },

    fetchSkuDataShopify(sku) {
      const platform = "shopify";
      if (sku.length > 2) {
        this.loading = true;
        apiClient
          .get(`/dataSync/sku?platform=${platform}&sku=${sku + ""}`)
          .then((response) => {
            const node = response.data;
            this.shopifyOptions[0] = { value: node.id, label: node.sku };
            this.loading = false;
          })
          .catch((error) => console.error("SKU检索失败:", error));
      }
    },
    fetchSkuDataEbay(sku) {
      const platform = "ebay";
      this.ebayOptions = [];
      if (sku.length > 2) {
        this.loading = true;
        apiClient
          .get(`/dataSync/sku?platform=${platform}&sku=${sku + ""}`)
          .then((response) => {
            const node = response.data;
            this.ebayOptions[0] = { value: node.id, label: node.sku };
            this.loading = false;
          })
          .catch((error) => console.error("SKU检索失败:", error));
      }
    },
    fetchSkuDataWordpress(sku) {
      const platform = "wordpress";
      if (sku.length > 2) {
        this.loading = true;
        apiClient
          .get(`/dataSync/sku?platform=${platform}&sku=${sku + ""}`)
          .then((response) => {
            const item = response.data;
            this.wordpressOptions[0] = { value: item.id, label: item.sku };
            this.loading = false;
          })
          .catch((error) => console.error("SKU检索失败:", error));
      }
    },
  },
  created() {
    this.fetchSyncData(this.currentPage);
  },
};
</script>

<style scoped>
.action-buttons {
  display: flex;
  align-items: center; /* 垂直居中对齐 */
  gap: 8px; /* 按钮之间的间距 */
  justify-content: center; /* 水平居中对齐 */
}

.el-button {
  min-width: 70px; /* 设置按钮的最小宽度，确保大小一致 */
  padding: 6px 0; /* 调整内边距 */
  text-align: center; /* 居中对齐 */
}

.sync-button {
  margin-bottom: 20px;
}

.action-buttons {
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
}

@media (max-width: 768px) {
  .action-buttons {
    justify-content: space-between;
  }
}
</style>
