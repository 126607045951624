// src/router/index.js
import { createRouter, createWebHistory } from 'vue-router';
import HomePage from '../views/HomePage.vue';
import UserLogin from '../views/UserLogin.vue';
import DataSync from '../views/DataSync.vue';

const routes = [
  { path: '/', name: 'Login', component: UserLogin },
  { path: '/home', name: 'Home', component: HomePage, 
    children: [
        {
          path: '/data-sync',
          name: 'DataSync',
          component: DataSync, // 数据同步组件
        },
        // 其他子路由...
      ],
    meta: { requiresAuth: true } }
];

const router = createRouter({
  history: createWebHistory(),
  routes
});

router.beforeEach((to, from, next) => {
  if (to.meta.requiresAuth && !localStorage.getItem('token')) {
    next({ name: 'Login' });
  } else {
    next();
  }
});

export default router;
